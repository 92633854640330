import React, { useEffect, useState } from "react";
import { Stack, Flex } from "@chakra-ui/react";
import Header from "../../Components/PressureDetailsComponents/Header";
import Form from "../../Components/PressureDetailsComponents/Form";

function PressureDetails({ data }) {

  return (
    <Stack p={4} w={"80%"} h={"100%"} overflow={"scroll"} backgroundColor={"white"}>
      <Flex
        m={10}
        justifyContent={"center"}
        borderBottom={"2px"}
        borderBottomColor={"black"}
      >
        <Header data={data} />
      </Flex>
      <Flex mt={20} justifyContent={"center"}>
        <Form data={data} />
      </Flex>
    </Stack>
  );
}

export default PressureDetails;
