import {Stack, Flex, Text, RadioGroup, Radio, Textarea} from '@chakra-ui/react'
import { Option } from './Option'
import React, {useState} from 'react'
import { Loading } from './Loading'

function Form({data}){
    const [selectedOption, setSelectedOption] = useState('')
    const [comment, setComment] = useState('')
    console.log(data)

    if (data) {
        return (
            <Stack gap={8} w={'80%'}>
                <Flex justifyContent={'center'}>
                    <Text fontSize={['x-large', 'xx-large', 'xxx-large']}>
                        Title: {data?.title}
                    </Text>
                </Flex>
                <Flex justifyContent={'center'}>
                    <Text fontSize={'large'}>
                        Date: {new Date(data?.created_formatted).toLocaleDateString()}
                    </Text>
                </Flex>
                <Flex>
                    <Text style={{whiteSpace: "pre-line"}}>
                        Description: {data?.description}
                    </Text>
                </Flex>
                <Flex>
                    <RadioGroup mb={6}>
                        <Stack direction='column' spacing={5}>
                            {data?.responseOptions.map((item) => {
                                return <Option key={item.uniqueId} option={item} setSelectedOption={setSelectedOption}/>
                            })}
                        </Stack>
                    </RadioGroup>
                </Flex>
                {/* <Flex>
                <Textarea
                    placeholder='Comments (optional)'
                    size='sm'
                    onChange={(e) => setComment(e.target.value)}
                />
                </Flex> */}
            </Stack>
        )
    } else return <Loading/>
    
}

export default Form