import "./AppRedirect-styles.css"
import { Stack } from "@chakra-ui/react";

function AppStoreRedirect(){

    return(
        <div className="modalWrapper">
            <h2>Pressures is not currently installed on your device.</h2>
            <p>Sign up for the beta test of Pressures with TestFlight.</p>
            <a className="button" 
                // href="https://testflight.apple.com/join/OS6HAGHa"
                href="https://pressures.app/dl"
            >Click here to install.</a>
            <a className="secondaryLink" href="/">Not now</a>
        </div>
    )
}

export default AppStoreRedirect;