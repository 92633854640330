import {Stack, Flex, Heading, Button} from '@chakra-ui/react'
import { useParams } from 'react-router-dom';

function Header(){
    const params = useParams();

    function isOnIOS(){
        if(typeof window === `undefined` || typeof navigator === undefined) return false;

        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    return (
        <Stack
            position={'fixed'}
            top={0}
            bg={'white'}
            minW={'100vw'}
            alignItems={'center'}
            p={2}
            pt={4}
        >
            <Flex>
                <Heading>
                    Pressure
                </Heading>
                {isOnIOS() && params.pressureId ? 
                    <Button 
                        onClick={()=>{
                            window.location.href = `api.pressures.app://p/${params.pressureId}`
                            setTimeout(()=>{
                                if(document.hasFocus()){
                                    window.location.href = '../appRedirect';
                                }
                            })
                        }}
                        position="absolute" right="5">
                        Open App
                    </Button> 
                : null}
            </Flex>
        </Stack>
    )
}

export default Header